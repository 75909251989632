import { Routes } from '@angular/router';
import { metaReconnectGuard } from '@core/guards/sync/meta-reconnect.guard';
import { BILLING_ROUTES } from '@features/settings/billing/billing.routes';
import { subscriptionLevelGuard } from '@features/settings/billing/guards/subscription-level.guard';
import { subscriptionGuard } from '@features/settings/billing/guards/subscription.guard';
import { SETTINGS_SLUGS } from '@routes/routes.types';
import { subscriptionCompletedGuard } from './billing/guards/subscription-completed.guard';
import { DEPARTMENTS_ROUTES } from './departments/departments.routes';

export const SETTINGS_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: SETTINGS_SLUGS.profile,
    },
    {
        path: '',
        loadComponent: () =>
            import('@features/settings/settings.component').then(m => m.SettingsComponent),
        children: [
            {
                path: SETTINGS_SLUGS.profile,
                title: 'Profile',
                loadComponent: () =>
                    import('@features/settings/profile/profile.component').then(
                        m => m.ProfileComponent
                    ),
                canActivate: [subscriptionGuard],
            },
            {
                path: SETTINGS_SLUGS.billing,
                title: 'Billing',
                loadComponent: () =>
                    import('@features/settings/billing/billing.component').then(
                        m => m.BillingComponent
                    ),
                children: BILLING_ROUTES,
                canDeactivate: [subscriptionCompletedGuard],
            },
            {
                path: SETTINGS_SLUGS.payment,
                title: 'Payment',
                loadComponent: () =>
                    import('@features/settings/payment-settings/payment-settings.component').then(
                        m => m.PaymentSettingsComponent
                    ),
                canActivate: [subscriptionGuard, subscriptionLevelGuard],
            },
            {
                path: SETTINGS_SLUGS.taxes,
                title: 'Taxes',
                loadComponent: () =>
                    import('@features/settings/taxes/taxes.component').then(m => m.TaxesComponent),
                canActivate: [subscriptionGuard],
            },
            {
                path: SETTINGS_SLUGS.users,
                title: 'Users',
                loadComponent: () =>
                    import('@features/settings/set-users/set-users.component').then(
                        m => m.SetUsersComponent
                    ),
                canActivate: [subscriptionGuard],
            },
            {
                path: SETTINGS_SLUGS.departments,
                title: 'Departments',
                loadComponent: () =>
                    import('@features/settings/departments/departments.component').then(
                        m => m.DepartmentsComponent
                    ),
                canActivate: [subscriptionGuard],
                children: DEPARTMENTS_ROUTES,
            },
            {
                path: SETTINGS_SLUGS.conversations,
                title: 'Conversations',
                loadComponent: () =>
                    import('@features/settings/conversations/conversations.component').then(
                        m => m.ConversationsComponent
                    ),
                canActivate: [subscriptionGuard],
            },
            {
                path: SETTINGS_SLUGS.thirdParty,
                title: 'Messaging channels',
                loadComponent: () =>
                    import(
                        '@features/settings/third-party-integrations/third-party-integrations.component'
                    ).then(m => m.ThirdPartyIntegrationsComponent),
                canActivate: [subscriptionGuard],
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        loadComponent: () =>
                            import(
                                '@features/settings/third-party-integrations/select-channel-page/select-channel-page.component'
                            ).then(m => m.SelectChannelPageComponent),
                    },
                    {
                        path: SETTINGS_SLUGS.meta,
                        title: 'Connect meta',
                        canDeactivate: [metaReconnectGuard],
                        loadComponent: () =>
                            import(
                                '@features/settings/third-party-integrations/connect-meta/connect-meta.component'
                            ).then(m => m.ConnectMetaComponent),
                    },
                    {
                        path: SETTINGS_SLUGS.whatsappQr,
                        title: 'Whatsapp QR',
                        loadComponent: () =>
                            import(
                                '@features/settings/third-party-integrations/whatsapp-qr/whatsapp-qr.component'
                            ).then(m => m.WhatsappQrComponent),
                    },
                    {
                        path: SETTINGS_SLUGS.whatsappCloud,
                        title: 'Whatsapp Business',
                        loadComponent: () =>
                            import(
                                './third-party-integrations/whatsapp-cloud/whatsapp-cloud.component'
                            ).then(m => m.WhatsappCloudComponent),
                    },
                ],
            },
            {
                path: SETTINGS_SLUGS.cancel,
                title: 'Cancel plan',
                loadComponent: () =>
                    import(
                        '@features/settings/cancel-subscript-page/cancel-subscript-page.component'
                    ).then(m => m.CancelSubscriptPageComponent),
            },
        ],
    },
];
